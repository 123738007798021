import BaseBean from "@/utils/BaseBean";

export interface IWjdListDataObj {
    utilInst:WjdListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class WjdListUtil extends BaseBean{
    public dataObj:IWjdListDataObj

    constructor(proxy:any,dataObj:IWjdListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        // this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({});//查询所有客户
    }
}